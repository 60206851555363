<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-check searchbar-content mr-5">
           <div class="title">
             <i class="fas fa-cog"></i>
             <span>{{ $t(`searchArea.date`) }}</span> :
           </div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
         </div>
         <div class="searchbar-content mr-5">
           <div class="title">
             <i class="fas fa-cog"></i>
             <span>{{ $t(`searchArea.schDiv`) }}</span> :
           </div>
           <input type="text" placeholder="ID" />
         </div>
         <button class="btn-search" type="button" @click="pageSeach">
           <i class="fas fa-search"></i>
         </button>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t(`searchArea.schResult`) }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">0</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">0</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">0</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <tr>
              <td>testtest</td>
              <td>1,500</td>
              <td>0</td>
              <td>1,500</td>
            </tr>
            <tr>
              <td>jose88</td>
              <td>0</td>
              <td>-72,566</td>
              <td>-72,566</td>
            </tr>
            <tr>
              <td>antman</td>
              <td>20</td>
              <td>795</td>
              <td>815</td>
            </tr>
            <tr>
              <td>solutionk1</td>
              <td>-700</td>
              <td>-795</td>
              <td>-1,495</td>
            </tr>
            <tr class="total">
              <th>{{ $t(`table.head.summary`) }}</th>
              <td>820</td>
              <td>-72,566</td>
              <td>-71,746</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import lodash from "lodash";
import TableHead from "@/components/main/table/Head.vue";
import DateSelector from "@/components/common/DateSelector";
import { partnerSummryList } from "@/api/partner.js";
import { getCode } from "@/api/common.js";
import { replaceDateT, numberWithCommas, getDateStr } from "@/libs/utils.js";
import Pagination from "@/components/common/Pagination";
import { GAME_INFO_LIST } from "@/libs/constants";
import { getSiteData } from "@/libs/auth-helper";
import subTitle from "@/components/main/PageSubTitle";
export default {
  name: "PartnerSummary",
  components: {
    TableHead,
    Pagination,
    DateSelector
  },
  data() {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ["memId"]
      },
      gameGroup: [],
      siteInfo: {},
      reqData: {
        page: 1,
        count_per_list: 30,
        count_per_page: 30,
        memId: "",
        startDate: "",
        endDate: "",
        ip: ""
      },
      dateConfigs: {
        enableTime: false,
        dateFormat: "Y-m-d"
      },
      startDefault: "",
      endDefault: "",
      pageInfo: {},
      totalInfo: {},
      tableData: [],
      games:[]
    };
  },
  methods: {
    setStartDate(date) {
      // console.log(date);
      let _date = "";
      if (!date) {
        _date = new Date();
      } else {
        _date = date[0];
      }
      _date.setHours(0, 0, 0);
      this.startDefault = _date;
      this.reqData.startDate = getDateStr(_date, "yyyy-MM-dd HH:mm:ss");
    },
    setEndDate(date) {
      // console.log(date[0]);
      let _date = "";
      if (!date) {
        _date = new Date();
      } else {
        _date = date[0];
      }
      _date.setHours(23, 59, 59);
      this.endDefault = _date;
      this.reqData.endDate = getDateStr(_date, "yyyy-MM-dd HH:mm:ss");
    },
    getSiteInfo() {
      const siteInfoCookie = getSiteData();
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie);
        return siteInfo;
      }
    },
    async getSummaryList() {
      const req = lodash(this.reqData);
      const res = await partnerSummryList(req);
      // console.log(res);
      return await res.data;
    },
    setThead() {
      console.log(this.gameGroup);
      const group = this.gameGroup;
      group.forEach((item)=>{this.headInfo.dataList.push(item.code)})
      this.headInfo.dataList.push("summary")
    },
    async getGameGroup() {
      const groups = await getCode("gameGroup");
      const newArr = groups.filter(item => {
        return item.useYn === "Y";
      });
      this.gameGroup = newArr;
    },
    async getGameCode() {
      const games = await getCode("kplay");
      const newArr = games.filter(item => {
        return item.useYn === "Y";
      });
      this.games = newArr;
    },
    async setTableData(page) {
      if (page) {
        this.reqData.page = page;
      } else {
        this.reqData.page = 1;
      }
      const req = lodash.cloneDeep(this.reqData);
      const data = await this.getSummaryList(req);
      const list = data.list;
      const userData = {};
      for (const item of list) {
        if (userData.hasOwnProperty(item.memId)) {
          console.log("kkk");
        } else {
          userData[item.memId] = {}
          console.log(this.gameGroup)
          for(const game of this.games){
            if(game.code === item.gameType){
              item.groupCode = game.groupCode;
            }
          }
          this.gameGroup.forEach((target)=>{
            userData[item.memId][target]="";
          })
        }
      }
      if (data.pageInfo) {
        this.pageInfo = data.pageInfo;
        this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count);
        this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1);
      }
      // this.tableData = data.list;
    }
  },
  async created() {
    await this.getGameGroup();
    await this.getGameCode();
    this.setThead();
    this.setStartDate();
    this.setEndDate();
    this.setTableData(1);
  }
};
</script>

<style scoped>
.pt50 {
  padding-top: 50px;
}
.mr10 {
  margin-right: 10px;
}
.total > td{
  font-weight: 800;
  background:#f0f0f0;
}
</style>
